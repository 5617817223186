class DropDown extends HTMLElement {
  constructor() {
    super();
    this.bindEvents();
  }

  bindEvents() {
    this.querySelector('.dropdown-trigger').addEventListener('click', this.toggle.bind(this));
  }

  toggle() {
    let dropdownTrigger = this.querySelector('.dropdown-trigger');
    let ariaExpanded = this.getAttribute('aria-expanded');
    let openTitle = this.getAttribute('open-title') || this.querySelector('.dropdown-title').textContent; // default title when opened
    let closedTitle = this.getAttribute('closed-title') || this.querySelector('.dropdown-title').textContent; // default title when closed

    if (dropdownTrigger.classList.contains('open')) {
      dropdownTrigger.classList.remove('open');
      this.querySelector('.dropdown-title').textContent = closedTitle;
    } else if (dropdownTrigger.classList.contains('open-desktop') && window.innerWidth > 1024) {
      dropdownTrigger.classList.remove('open-desktop');
      this.querySelector('.dropdown-title').textContent = closedTitle;
    } else {
      dropdownTrigger.classList.add('open');
      this.querySelector('.dropdown-title').textContent = openTitle;
    }

    if (ariaExpanded === 'true') {
      this.setAttribute('aria-expanded', 'false');
    } else {
      this.setAttribute('aria-expanded', 'true');
    }
  }
}

customElements.define('drop-down', DropDown);
